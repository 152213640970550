export default {
  error_occurred: 'Sorry, er is een fout opgetreden. Probeer het zo meteen opnieuw.',
  new_content_available: "Er is een nieuwe pagina-update, klik op 'Vernieuwen' om de pagina opnieuw te laden.",
  refresh_button_title: 'Vernieuwen',
  force_reload_message: 'Sorry, er is een fout opgetreden. Ons team is er al mee bezig. Probeer de website zo snel mogelijk opnieuw te laden.',
  sign_up: 'Account aanmaken',
  service: 'Onderhoud',
  employee: 'Medewerker',
  select_employee: 'Selecteer een persoon',
  employees: 'Medewerkers',
  services: 'Diensten',
  duration: 'Looptijd',
  from: 'Van',
  select_date: 'Selecteer een datum',
  no_account_question: 'Heb je nog geen account?',
  name: 'Voornaam',
  surname: 'Achternaam',
  name_and_surname: 'Voor-en achternaam',
  phone: 'Telefoon',
  password: 'Wachtwoord',
  field_is_required: 'Dit veld is verplicht.',
  value_is_too_long: 'De waarde is te lang.',
  value_is_too_short: 'De waarde is te kort.',
  signup_was_successful: 'De registratie is gelukt. Aanmelden bij het paneel.',
  signup_has_failed: 'De registratie is mislukt. Probeer het zo meteen opnieuw.',
  email_is_taken: 'Het account met het opgegeven e-mailadres bestaat al. Gebruik de herinneringsoptie als u uw wachtwoord bent vergeten.',
  email_or_password_incorrect: 'Het e-mailadres of wachtwoord is onjuist. Gebruik de herinneringsoptie als u uw wachtwoord bent vergeten.',
  account_not_found: 'Account niet gevonden.',
  page_not_found: 'Oeps! De pagina die u zocht kon niet worden gevonden :(',
  go_back_to_main_page: 'Ga terug naar de startpagina en probeer het opnieuw.',
  bookings: 'Boekingen',
  account: 'Mijn rekening',
  date: 'Datum',
  cancel: 'Annuleren',
  date_from: 'Datum vanaf',
  date_to: 'Datum naar',
  nothing_found_here: 'Er werd niets gevonden.',
  old_password: 'Oud Wachtwoord',
  remove: 'Verwijderen',
  phone_is_invalid: 'Het telefoonnummer is onjuist.',
  book: 'Boek',
  sign_up_2: 'Account aanmaken',
  log_in: 'Log in',
  your_email_address: 'Jouw e-mailadres',
  select: 'Selecteer',
  filters: 'Filters',
  expand: 'Uitbreiden',
  collapse: 'Instorten',
  bookings_drawer_title: 'Selecteer filters om alleen de boekingen te tonen waarnaar u op zoek bent.',
  clear_filters: 'Filters resetten',
  show_more: 'Laat meer zien',
  dont_you_remember_password: 'Je wachtwoord vergeten?',
  password_information: 'Het wachtwoord moet uit minimaal 8 tekens bestaan, een hoofdletter, een cijfer en een speciaal teken bevatten.',
  forgotten_password_button: 'Stuur een link om je wachtwoord te wijzigen',
  do_you_already_have_an_account: 'Heeft u al een account?',
  reset_password_title: 'Nieuw wachtwoord instellen',
  reset_password_subtitle: 'Voer je nieuw wachtwoord hieronder in.',
  reset_password_button: 'Bewaar het nieuwe wachtwoord',
  profile: 'Profiel',
  logout: 'Afmelden',
  cancel_booking: 'Annuleer uw boeking',
  status_approved: 'Goedgekeurd',
  status_payment: 'In afwachting van betaling',
  status_done: 'Voltooid',
  status_cancelled: 'Geannuleerd',
  cancel_booking_dialog_title: 'Wilt u uw boeking annuleren?',
  cancel_booking_dialog_information: 'Deze actie kan niet ongedaan gemaakt worden!',
  cancel_booking_dialog_button: 'Ja, annuleer mijn boeking',
  my_profile: 'Mijn profiel',
  my_data: 'Mijn details',
  upload_new_photo: 'Voeg een nieuwe foto toe',
  birthday: 'Verjaardag',
  save_changes: 'Wijzigingen opslaan',
  phone_number: 'Telefoonnummer',
  directional: 'Netnummer',
  save_new_password: 'Bewaar het nieuwe wachtwoord',
  new_password: 'Nieuw paswoord',
  password_change: 'Verander wachtwoord',
  view: 'Visie',
  services_2: 'Diensten',
  accept_terms_and_conditions_1: 'Door op de onderstaande optie te klikken, ga ik akkoord met de',
  accept_terms_and_conditions_2: 'Voorwaarden',
  accept_terms_and_conditions_3: 'en ik bevestig dat ik de . heb gelezen',
  accept_terms_and_conditions_4: 'Privacyverklaring.',
  to: 'Tot',
  pln: '@{value} PLN',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Log in',
  email_is_incorrect: 'Het e-mailadres is onjuist.',
  logged_out: 'Je bent uitgelogd',
  booking_dialog_date_is_already_taken: 'De geselecteerde tijd is niet beschikbaar. Zou je een andere plek willen kiezen?',
  data_search: 'Op zoek naar gegevens. Even geduld aub...',
  no_employees: 'Geen werknemers',
  no_services: 'Geen diensten',
  no_categories: 'Geen categorieën',
  no_bookings: 'Boekingen niet gevonden.',
  no_subscriptions: 'Er zijn geen abonnementen gevonden.',
  female: 'Vrouw',
  male: 'Mannelijk',
  other: 'Ander',
  no_numeric_in_password: 'Geen numeriek teken in het wachtwoord.',
  no_lowercase_in_password: 'Geen kleine letter in het wachtwoord.',
  no_uppercase_in_password: 'Geen hoofdletter in het wachtwoord.',
  no_special_character: 'Geen speciaal karakter.',
  min_n_characters: 'Het minimum aantal karakters is @{min}.',
  max_n_characters: 'Het maximum aantal tekens is @{max}.',
  email: 'E-mail',
  reset_password_fail_text: 'Het wachtwoord is niet gewijzigd. De link is waarschijnlijk verlopen.',
  reset_password_success_title: 'Uw wachtwoord is veranderd.',
  reset_password_success_text: 'U kunt inloggen met uw nieuwe wachtwoord.',
  account_activation: 'Account activatie',
  account_activation_success: 'Het account is geactiveerd.',
  account_activation_fail: 'Er is een probleem opgetreden bij het activeren van uw account. Het lijkt erop dat het account al actief is of dat de link niet meer geldig is.',
  account_activation_processing: 'Uw account activeren. Even geduld aub...',
  account_activation_resend_title: 'Het account is niet geactiveerd',
  account_activation_resend_text: 'Activeer uw account door op de activeringslink te klikken in de e-mail die we u hebben gestuurd.',
  account_activation_resend_button: 'Opnieuw versturen',
  confirm_send_activate_link_dialog_subtitle: 'We hebben een e-mail met de activeringslink naar het eerder opgegeven e-mailadres gestuurd.',
  file_is_too_large: 'Het geselecteerde bestand is te groot.',
  appointment_title: 'Selecteer een persoon, datum en tijd van boeking',
  others: 'Ander',
  countryLabel: 'Land',
  requiredMessages: 'Een telefoonnummer is vereist',
  date_of_visit: 'Datum',
  hour_of_visit: 'Tijd',
  thank_you_for_booking: 'Jouw reservering',
  no_free_hours: 'Geen beschikbare tijdsloten op de geselecteerde dag.',
  any_person: 'Willekeurige keuze',
  password_changed: 'Uw wachtwoord is veranderd',
  incorrect_old_password: 'Het oude wachtwoord is onjuist',
  error404: 'Foutmelding 404',
  minutes: 'min',
  hours: 'h',
  days: 'd',
  ok: 'Oké',
  web_page_temporary_disabled: 'Sorry! Deze pagina is tijdelijk uitgeschakeld.',
  profile_update_success: 'Uw gegevens zijn opgeslagen.',
  form_is_invalid: 'Het formulier bevat fouten. Controleer de gemarkeerde velden en probeer het opnieuw.',
  search: 'Zoeken',
  pay_online_button_title: 'Betaal online',
  account_not_activated_info: 'Het e-mailadres is niet bevestigd. Bevestig uw e-mailadres door op de link in het verzonden bericht te klikken.',
  paid: 'Betaald',
  payments: 'Betalingen',
  payment_service: 'Onderhoud',
  payment_date: 'Datum',
  payment_price: 'Prijs',
  payment_success_title: 'De betaling is gelukt, bedankt!',
  payment_fail_title: 'De betaling is mislukt, probeer het opnieuw.',
  pay_again: 'Betaal opnieuw',
  newsletter_subscribe_success: 'Dank je! Uw e-mailadres is bevestigd.',
  newsletter_subscribe_fail: 'Er is een probleem opgetreden bij het activeren van uw e-mailadres. Het lijkt erop dat het e-mailadres al actief is of dat de link niet meer geldig is.',
  newsletter_send_again: 'Opnieuw versturen',
  newsletter_send_again_success: 'De activatielink is opnieuw verzonden.',
  send_again_fail: 'Er is een probleem opgetreden bij het genereren van een nieuwe activeringslink, misschien is uw e-mailadres al actief of heeft u de link zojuist gegenereerd.',
  newsletter_email_is_used: 'Het e-mailadres staat al op de lijst, als het adres niet is bevestigd, kunnen we de activeringslink opnieuw verzenden.',
  newsletter_unsubscribe_success: 'Dank je! Uw e-mailadres is verwijderd.',
  newsletter_unsubscribe_fail: 'Er is een probleem opgetreden bij het verwijderen van het e-mailadres. Het lijkt erop dat het e-mailadres al is verwijderd of dat de link is verlopen.',
  booking_payments: 'Boekingen',
  simple_store_product_transactions: 'Producten',
  payment_type_select_box: 'Selecteer het type betaling',
  payment_description: 'Beschrijving',
  add_to_cart: 'Voeg toe aan winkelkar',
  remove_from_cart: 'Uit de winkelwagen halen',
  save: 'Opslaan',
  bookings_cart_summary: 'Boekingsoverzicht',
  today: 'Vandaag',
  fill_booking_details_title: 'Voer details in',
  create_an_account_question: 'Maak een account aan en stuur het wachtwoord naar het e-mailadres',
  value_is_incorrect: 'Deze waarde is niet geldig.',
  first_available_date: 'Beschikbare tijdvakken aan',
  service_location_1: 'Adres',
  service_location_2: 'Telefoon',
  service_location_3: 'Google Meet',
  service_location_4: 'Ander',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Plaats',
  booking_not_found: 'Boeking niet gevonden.',
  booking_canceled: 'De boeking is geannuleerd.',
  booking_canceled_fail: 'Deze boeking kan niet worden geannuleerd.',
  day: 'Dag',
  week: 'Week',
  month: 'Maand',
  year: 'Jaar',
  booking_plural_1: 'Boeking',
  booking_plural_2: 'Boekingen',
  booking_plural_3: 'Boekingen',
  booking_plural_4: 'Boekingen',
  booking_plural_5: 'Boekingen',
  day_plural_1: 'Dag',
  day_plural_2: 'Dagen',
  day_plural_3: 'Dagen',
  day_plural_4: 'Dagen',
  day_plural_5: 'Dagen',
  week_plural_1: 'Week',
  week_plural_2: 'Weken',
  week_plural_3: 'Weken',
  week_plural_4: 'Weken',
  week_plural_5: 'Weken',
  month_plural_1: 'Maand',
  month_plural_2: 'Maanden',
  month_plural_3: 'Maanden',
  month_plural_4: 'Maanden',
  month_plural_5: 'Maanden',
  year_plural_1: 'Jaar',
  year_plural_2: 'Jaren',
  year_plural_3: 'Jaren',
  year_plural_4: 'Jaren',
  year_plural_5: 'Jaren',
  tax: 'BTW-nummer',
  tax_prefix: 'Voorvoegsel',
  get_company_data: 'Zoeken',
  get_company_data_failed: 'De gegevens konden niet worden gedownload.',
  company_name: 'Bedrijfsnaam',
  street_and_number: 'straat en nummer',
  postcode: 'Postcode',
  city: 'Stad',
  country: 'Land',
  subscribe: 'Abonneren',
  recurrent_fee_info: 'Elke gefactureerd',
  one_time_fee_info: 'Eenmalige vergoeding.',
  new_account_title: 'Maak een nieuw account aan',
  payment_data_title: 'Betalingsdetails',
  billing_data_checkbox_question: 'Factuurgegevens',
  confirmation_modal_header: 'De actie vereist bevestiging',
  confirmation_modal_warning: 'Weet je zeker dat je door wilt gaan?',
  no_way_to_undo_action: 'Deze actie kan niet ongedaan gemaakt worden!',
  delete_payment_method: 'Kaart verwijderen',
  add_payment_method: 'Voeg een kaart toe',
  fill_card_details: 'Vul de betalingsgegevens in.',
  subscriptions: 'Abonnementen',
  subscription: 'Abonnement',
  invoice_number: 'Factuur',
  number: 'Nummer',
  status: 'Toestand',
  details: 'Details',
  created_at: 'Gemaakt',
  subscription_status_initiated: 'Onbetaald',
  subscription_status_active: 'Actief',
  subscription_status_inactive: 'Inactief',
  subscription_status_canceled: 'Geannuleerd',
  ends_at: 'Eindigt bij',
  ends_at_default: 'Geldig tot het einde van de factureringsperiode',
  select_subscription_to_pay: 'Selecteer een abonnement om te betalen.',
  pay_with_subscription: 'Betalen met abonnement',
  bookings_paid_by_subscription_success_message: 'Betaald',
  bookings_not_paid_by_subscription_error_message: 'De boeking kon niet worden betaald met het geselecteerde abonnement.',
  stripe: 'Stripe',
  provider_tag: 'Methode',
  card: 'Kaart',
  cash: 'Contant geld',
  transfer: 'Overdracht',
  promo_code_label: 'Kortingscode',
  promo_code_invalid_message: 'De opgegeven kortingscode is ongeldig.',
  payment_method_setup_error: 'Er is een probleem opgetreden met uw kaart. Uw bank heeft geweigerd de kaart te autoriseren voor latere transacties. Neem dan contact op met uw bank.',
  active_subscription_missing: 'Boeken voor deze service vereist een actief abonnement. Helaas heb je geen abonnement om te gebruiken.',
  area_code: 'Netnummer',
  usage: 'Gebruik',
  create_account_in_stripe_failed: 'De betalingsoperator heeft uw abonnementsgegevens afgewezen. Controleer de juistheid van het formulier (fiscaal identificatienummer, telefoonnummer, e-mailadres).',
  additional_auth_required: 'Uw bank heeft aanvullende autorisatie nodig',
  general_payment_error: 'Er is een probleem opgetreden met uw betaalmethode. Misschien ondersteunt uw kaart geen abonnementen. Neem contact op met je bank of kies een andere betaalmethode.',
  subscription_set_successfully: 'Abonnement correct ingesteld.',
  booking_created: 'Nieuwe boeking',
  booking_updated: 'Wijziging boeking',
  booking_deleted: 'Een boeking verwijderen',
  booking_reminder: 'Boekingsherinnering',
  email_notifications: 'E-mail notificaties',
  sms_notifications: 'SMS-meldingen',
  save_user_preferences: 'Voorkeuren opslaan',
  free_spots: 'Nog plekjes',
  no: 'Nee',
  yes: 'Ja',
  p24_inactive: 'De betalingsoperator Przelewy24 kan niet worden gebruikt. Neem contact op met uw betalingsprovider.',
  stripe_customer_invalid: 'Ongeldige Stripe-klant-ID. De betaalmethode kan niet aan de klant worden gekoppeld.',
  stripe_resource_invalid: 'Het geselecteerde product is niet beschikbaar. Controleer de beschikbaarheid van het product bij de betalingsprovider.',
  account_blocked_message: 'Uw account is inactief. Neem dan contact op met de beheerder.',
  time_zone: 'Tijdzone',
  no_spots_left: 'Je hebt het maximum aantal beschikbare plekken bereikt.',
  employee_auth_required: 'Log in op uw account of gebruik een ander e-mailadres. Werknemersaccounts vereisen inloggen.',
  subpage_auth_required: 'Log in op uw account om het tabblad te bekijken.',
  gross: 'Brutobedrag.',
  subscription_login_info: 'Heb je al een account? Gelieve hieronder in te loggen om uw gegevens in te vullen.',
  change_date_time: 'Afspraak verzetten',
  promo_code_checkbox: 'Ik heb een kortingscode',
  booking_promo_code_label: 'Vul de kortingscode in',
  select_hour: 'Kies tijd',
  id: 'Id',
  booking_rescheduled: 'Uw boeking is gewijzigd.',
  booking_rescheduled_fail: 'Deze boeking kan niet worden gewijzigd.',
  max_user_bookings_error: 'U heeft het maximum aantal boekingen overschreden. Kies een andere dienst of neem contact met ons op.',
  in_total: 'Totaal',
  company: 'Bedrijf',
  individual: 'Prive persoon',
  bulk_payment: 'Bulk betaling',
  make_bulk_payment: 'Bulk betaling',
  simple_store_product_confirmation_title: 'Vul het aankoopformulier in',
  select_service_type: 'Selecteer een serviceoptie',
  add_to_calendar_button_label: 'Opslaan in Agenda',
  login_to_use_service_with_subscription: 'Log in op uw account om een afspraak te maken voor deze service.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'De gekozen datum is niet beschikbaar. Kies alstublieft een andere beschikbare datum. Zorg er bij het reserveren van meerdere data voor dat de data elkaar niet uitsluiten.',
  select_location_calendar_warning: 'Selecteer een locatie om beschikbare tijden te laden.',
  selectable_location_online_title: 'Online',
  change: 'Verander',
  show_less_hours_button: 'Toon minder uren',
  show_more_hours_button: 'Toon meer uren',
  add_another_button_title: 'Voeg nog een toe',
  close: 'Sluiten',
  selected_time_slots: 'Geselecteerde tijdslots',
  select_location: 'Selecteer locatie'
}
